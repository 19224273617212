import logoValk from './img/backgroundFooter.png'
import whatsapp from './img/whatsapp.png'
import instagram from './img/instagram.png'
import email from './img/email.png'
import logo_Valk from './img/logo valk.png';

export default function Footer() {
    return (
        <div className="footerPai">
            <div className="footer" id="footer" style={{ backgroundImage: `url(${logoValk})` }}>
                <div className='footerConteudo'>
                    <div className='conteudoLeft'>
                        <h2 className="frasefooter"><span>ENTRE EM </span>CONTATO</h2>
                        <div className='iconesFooter'>
                            <a href="https://wa.me//+5511984521351" target="_blank"><img src={whatsapp} className="whatsapp" /> </a>
                            <a href="https://www.instagram.com/andersonsantosde654?utm_source=qr&igsh=dXUwdDlqdm1waHlv" target="_blank"><img src={instagram} className="instagram" /></a>
                            <a href="mailto:oliveira.andersonsantos123@gmail.com" target="_blank"><img src={email} className="email" /></a>
                        </div>
                    </div>
                    <div className='conteudoRight'>
                        <img src={logo_Valk} className="footerLogo" />
                    </div>
                </div>


            </div>

            <div className="footerMobile" id="footerMobile">
                <div className='footerConteudo'>

                    <div className='conteudoLeft2'>
                        <h2 className="fraseLeftMobile">VALK</h2>
                        <p className="fraseLeftMobile4">Engenharia e Serviços ME</p>
                    </div>

                    <div className='conteudoRight'>
                        <h2 className="fraseLeftMobile3"><span> ENTRE EM </span>CONTATO</h2>
                        <div className='iconesFooterMobile'>
                            <a href="https://wa.me//+5511984521351" target="_blank"><img src={whatsapp} className="whatsapp"/></a>
                            <a href="https://www.instagram.com/andersonsantosde654?utm_source=qr&igsh=dXUwdDlqdm1waHlv" target="_blank"><img src={instagram} className="instagram"/></a>
                            <a href="mailto:oliveira.andersonsantos123@gmail.com" target="_blank"><img src={email} className="email"/></a>

                        </div>
                    </div>
                </div>


            </div>
        </div>



    );
}