import porta from './img/porta.png'
import img_atv from './img/img_atv.png'
import Hose from './img/Hose.png'
import Fireman_hose from './img/Fireman_hose.png'
import sprinkler from './img/sprinkler.png'
import Firefighter from './img/Firefighter.png'
import detector from './img/detector.png'
import raio from './img/raio.png'
import renovacao from './img/renovacao.png'
import clipboard from './img/clipboard.png'

export default function Atividades() {
    return (
        <div className="atividades" id='atividades'>
            <div> <img src={img_atv} className="logo_atv"/> </div>
            <h1 className="atividadadeR">SERVIÇOS PRESTADOS</h1>
                <div className="grid-container">
                    <div className="grid-item"> <img src={porta} className="porta"/> <p className="itemTitulo">Iluminação de Emergência</p>  </div>
                    <div className="grid-item"> <img src={Hose} className="hose"/> <p className="itemTitulo">Mangueiras de Incêndio</p></div>
                    <div className="grid-item"> <img src={sprinkler} className="sprinkler"/><p className="itemTitulo">Sprinklers</p></div>
                    <div className="grid-item"> <img src={Fireman_hose} className="bomb_hose"/><p className="itemTitulo">Projeto Técnico de Combate a Incêndio</p></div>
                    <div className="grid-item"> <img src={clipboard} className="clipboard"/><p className="itemTitulo">Laudos e Atestados</p></div>
                    <div className="grid-item"> <img src={detector} className="detector"/><p className="itemTitulo">Alarmes e Detecção</p></div>
                    <div className="grid-item" id="fireFighterDiv"> <img src={Firefighter} className="firefighter"/><p className="itemTitulo">Brigada Contra Incêndio</p> </div>
                    <div className="grid-item" id="fireFighterDiv"> <img src={renovacao} className="renovacao"/><p className="itemTitulo">Renovação de AVCB</p></div>
                    <div className="grid-item-raio"> <img src={raio} className="raio"/><p className="itemTitulo">Para-Raio - SPDA</p></div>
                </div>
                <div className="grid-item-raio2"> <img src={raio} className="raio"/><p className="itemTitulo">Para-Raio - SPDA</p></div>
                <div className="info">

                    <p className="Paragrafo"> Executamos todas as adequações necessárias para garantir o atendimento à legislação vigente do corpo de bombeiros e assim obter ou renovar o alvará (AVCB|CLCB) </p>
                    
                </div>
        </div>
    );
}

