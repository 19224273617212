import mapa from './img/mapa2.png'
import mapa2 from './img/mapa.png'

export default function Localizacao(){
    return(
        <div className="localizacao" id='localizacao'> 
            <div>
            
            <img src = {mapa} className="mapa"/> 

            </div>

            <h2 className="localizacaoFrase1">LOCALIZAÇÃO</h2>                                          

         <div className="localizacaoFrase2">

            <h3 className="fraseMapa">Rua Chacuru, 165, Vila Curuca, São Paulo - SP</h3>
        
        </div>

        <div className="localizacaoMapa">
            <div className="mapa">
        <div className="googlemap"><iframe width="900" height="430" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=R.%20Chacuru,%20165%20-%20Vila%20Curu%C3%A7%C3%A1%20Velha+(My%20Business%20Name)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe>
        </div>
        </div>
        
        <div className="googlemap2"><iframe width="400" height="250" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=R.%20Chacuru,%20165%20-%20Vila%20Curu%C3%A7%C3%A1%20Velha+(My%20Business%20Name)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>
        <h3 className="mapaFrase2">Rua Chacuru, 165, Vila Curuca, São Paulo - SP</h3>
        </div>
       
         </div>
         
    );
    
}