import avcb from './img/avcb.png'
import pessoa from './img/pessoa.png'
import postit from './img/Postit.png'

export default function Servico() {
    return (
        <div className='servicoBody' id='servico'>
            <div> <img src={pessoa} className="pessoa" /> </div>
            <div> <h2> Sobre os Serviços </h2></div>
            <div className="servico">
                <div className="img_avcb">
                    <img src={avcb} className="avcb" />
                </div>

                <div>
                    <h3 className='textao'> Seu negócio é uma parte vital da comunidade, e manter todos seguros é uma responsabilidade grande. Em São Paulo, o AVCB (Auto de Vistoria do Corpo de Bombeiros) e o CLCB (Certificado de Licenciamento do Corpo de Bombeiros) não são apenas documentos – são a prova de que você leva a sério a segurança contra incêndio.
                        Ter essas certificações significa que você está protegido contra surpresas indesejadas, desde multas até situações de risco real. Além disso, elas são essenciais para a legalidade e operação do seu negócio.
                        O que são AVCB e CLCB?
                        AVCB é necessário para edificações com área construída superior a 750 metros quadrados, ou independentemente da área, quando destinadas a reunião de público, como escolas, cinemas e teatros.
                        CLCB, por outro lado, é direcionado a estabelecimentos de menor risco e com área construída de até 750 metros quadrados. Ainda assim, é fundamental demonstrar que as medidas de segurança contra incêndio estão em dia.
                        “Este certificado valida que seu estabelecimento passou por uma inspeção detalhada e cumpre com os rigorosos padrões de segurança contra incêndio”. </h3>
                </div>

                <img src={postit} className="postit" />                

            </div>
        </div>
    );

}