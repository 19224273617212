import logoValk from './img/logo valk.png';
import geral from './img/01.jpg';
import certificado from './img/02.png';
import sprinkler from './img/03.png';
import bombeiros from './img/bombeiros.png';
import menu from './img/menu.png';

export default function Header() {
    
    


        return (

            //Evento de click do botao
  

            <div className="header" id='header'>
                <div className="searchBar">
                    <div className="divBurguer">
                        <button onClick={() => {var menu = document.querySelector('.menuLateral')
                        menu.classList.toggle('menuLateral--ativo')
                    }} className="menu" > <img src={menu} className="menuImagem" alt="Menu de navegação"/></button>
                    </div>
                    <div className="left">
                        <img src={logoValk} className="logoValk" alt="Logo Valk" />
                    </div>
                    <div className="right">
                        <a href="#header">Início</a><a href="#atividades">Atividades</a><a href="#footer">Contato</a>
                    </div>
                </div>
                <div className="banner">
                    <div className="carrosselFrase">
                        SOMOS ESPECIALISTAS EM OBTER OU RENOVAR AVCB E/OU CLCB
                    </div>
                    <div id="slider">
                        <input type="radio" name="slider" id="slide1" defaultChecked />
                        <input type="radio" name="slider" id="slide2" />
                        <input type="radio" name="slider" id="slide3" />
                        <input type="radio" name="slider" id="slide4" />
                        <div id="slides">
                            <div id="overflow">
                                <div className="inner">
                                    <div className="slide slide_1">
                                        <div className="slide-content">
                                            <img src={geral} className="certificado"/>
                                        </div>
                                    </div>
                                    <div className="slide slide_2">
                                        <div className="slide-content">
                                            <img src={certificado} className="treinamento"/>
                                        </div>
                                    </div>
                                    <div className="slide slide_3">
                                        <div className="slide-content">
                                        <img src={sprinkler} className="curso"/>
                                        </div>
                                    </div>
                                    <div className="slide slide_4">
                                        <div className="slide-content">
                                        <img src={bombeiros} className="bombeiros"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="controls">
                            <label htmlFor="slide1"></label>
                            <label htmlFor="slide2"></label>
                            <label htmlFor="slide3"></label>
                            <label htmlFor="slide4"></label>
                        </div>
                        <div id="bullets">
                            <label htmlFor="slide1"></label>
                            <label htmlFor="slide2"></label>
                            <label htmlFor="slide3"></label>
                            <label htmlFor="slide4"></label>
                        </div>
                    </div>
                </div>

                <div className="menuLateral">

                <div className='conteudoLeft'>
                        <h2 className="fraseLeftMobile">VALK</h2>
                        <p className="fraseLeftMobile2">Engenharia e Serviços ME</p>
                    </div>
                 <a href="#header">Início</a><a href="#atividades">Atividades</a><a href="#footerMobile">Contato</a>
                
                    
                    
                </div>

            </div>
            
        );
        
    };
    