
import React, { useEffect } from 'react';
//CSS Imports
import './css_reset.css';
import './App.css';

//Import Components
import Header from './components/Header.js';
import Atividades from './components/Atividades.js';
import Localizacao from './components/Localizacao.js';
import Servico from './components/Servico.js';
import Footer from './components/Footer.js';

const App = () => {

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, []);

  return (
    <div className="body">
      <Header/>
      <Atividades/>
      <Localizacao/>
      <Servico/>
      <Footer/>
    </div>
  );
}

export default App;
